import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import dom from '../../wrapper/DomWrapper';
import CookieAnalyticsManager from '../CookieBannerManager/CookieAnalyticsManager';
import uuid from '../../helpers/uuid';

import {
  getScreenResolution,
  postAnalyticApiRequest,
  postNavigatorRequest,
} from './utils';
import { LOCAL, LEAVE_WEBSITE_ACTION, ENTER_WEBSITE_ACTION } from './constants';

class PlatformAnalytics {
  constructor(projectId, isGdprEnabled) {
    this.analyticsManager = new CookieAnalyticsManager();
    this.payload = {
      transactionId: uuid(),
      action: ENTER_WEBSITE_ACTION,
      cookie: this.analyticsManager.getCookies(),
      projectId,
      screenResolution: getScreenResolution(),
      path: dom.window.location.pathname,
    };
    this.isGdprEnabled = isGdprEnabled;
  }

  init = async () => {
    try {
      const response = await postAnalyticApiRequest(this.payload);
      this.handleAnalytic(response);
    } catch (error) {
      console.warn(error);
    }
  }

  handleUnload = () => {
    const isAnalyticsEnabled = !this.isGdprEnabled || !!this.analyticsManager.getInitialValue();

    if (!isAnalyticsEnabled) return;

    postNavigatorRequest({
      ...this.payload,
      action: LEAVE_WEBSITE_ACTION,
    });
  }

  handleAnalytic = async (response) => {
    if (!get(response, ['ok'], false)) return;

    dom.on(dom.window, 'unload', this.handleUnload);
    const data = await response.json();

    if (isEmpty(data)) return;

    Object.keys(data).forEach((key) => {
      const {
        value, maxAge, domain, ...options
      } = data[key];
      const maxAgeOptions = maxAge ? { maxAge: maxAge.toString() } : {};
      const domainOptions = domain ? { domain: domain.indexOf(LOCAL) >= 0 ? LOCAL : domain } : {};
      const analyticsOptions = {
        ...options,
        ...maxAgeOptions,
        ...domainOptions,
      };

      this.analyticsManager.setCookies(key, value, analyticsOptions);
    });
  };
}

export default PlatformAnalytics;
