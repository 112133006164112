import dom from '../../wrapper/DomWrapper';

import GoogleAnalytics from './GoogleAnalytics';
import PlatformAnalytics from './PlatformAnalytics';
import { getQueryParams, checkIsAnalyticsEnabled, checkIsLocalhost } from './utils';
import getStateValue from '../../helpers/getStateValue';
import checkIsGdprEnabled from '../../helpers/checkIsGdprEnabled';

const initAnalytics = async () => {
  const { accessToken } = getQueryParams(dom.window.location.search);
  const isAnalyticsEnabled = checkIsAnalyticsEnabled();
  const isLocalhost = checkIsLocalhost();
  const isGdprEnabled = await checkIsGdprEnabled();
  const isAnalyticsDisabled = isGdprEnabled && (!!accessToken || !isAnalyticsEnabled);

  if (isLocalhost || isAnalyticsDisabled) return;

  const gaTrackingId = getStateValue(['analytics', 'googleAnalyticsId'], '');
  const gtmContainerId = getStateValue(['analytics', 'googleTagManagerId'], '');
  const projectId = getStateValue('projectId', '');
  const googleAnalyticsSettings = { gaTrackingId, gtmContainerId };

  if (gaTrackingId || gtmContainerId) new GoogleAnalytics(googleAnalyticsSettings).init();
  if (projectId) new PlatformAnalytics(projectId, isGdprEnabled).init();
};

export default initAnalytics;
